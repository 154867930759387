<template>
  <section class="section pt-2">
    <p class="title has-text-white">Terminais</p>

    <table class="table is-fullwidth">
      <tr>
        <th>#</th>
        <th>Nome</th>
        <th>Tipo</th>
        <th>Ação</th>
      </tr>
      <tr v-for="terminal in terminals" :key="terminal._id">
        <td>{{ terminal.number }}</td>
        <td>{{ terminal.name }}</td>
        <td>
          <svg
            v-show="terminal.type == 'ticket'"
            style="width: 24px; height: 24px"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z"
            />
          </svg>
          <svg
            v-show="terminal.type == 'call'"
            style="width: 24px; height: 24px"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M21,17H3V5H21M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H8V21H16V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z"
            />
          </svg>
        </td>
        <td>
          <a
            @click.prevent="openTerminal(terminal)"
            href
            class="button is-primary is-small"
            >Abrir Terminal</a
          >
        </td>
      </tr>
    </table>
  </section>
</template>
<script>
export default {
  name: "Terminals",
  data() {
    return {
      terminals: [],
    };
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_URI}terminal`)
      .then((response) => (this.terminals = response.data));
  },
  methods: {
    openTerminal(terminal) {
      this.$store.commit("setTerminal", terminal);
      if (terminal.type == "ticket")
        this.$router.push(`/terminal_ticket/${terminal._id}`);
      if (terminal.type == "call")
        this.$router.push(`/terminal_call/${terminal._id}`);
    },
  },
};
</script>
